@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';

.super-header {
  @include layout.max-width;
  @include layout.row;
  position: relative;
  justify-content: space-between;
  height: variables.$SUPER_HEADER_HEIGHT;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: variables.$color-lighter;
  opacity: 0.9;
  transition: variables.$transition-s;

  span,
  a {
    @include variables.paragraph;
    color: variables.$color-disabled;
  }

  @media (max-width: variables.$device-md) {
    padding-right: 1rem;
    padding-left: 1rem;
    opacity: 1;
  }
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  background: variables.$color-near-white;

  &::after {
    position: absolute;
    top: 1px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background: rgba(variables.$color-disabled, 0.15);
    content: ' ';
  }
}

.nav {
  @include layout.row(0.25rem);

  @media (max-width: variables.$device-md) {
    gap: 0.125rem;
  }
}

.link {
  border-radius: 4rem;
  padding: 0.125rem 0.75rem;
  transition: variables.$transition-s;

  &[data-active='true'] {
    background-color: rgba(variables.$color-disabled, 0.15);

    &:hover {
      background-color: rgba(variables.$color-disabled, 0.25);
    }

    @media (max-width: variables.$device-md) {
      background-color: rgba(variables.$color-disabled, 0.15);

      &:hover {
        background-color: rgba(variables.$color-disabled, 0.15);
      }
    }
  }

  &:hover {
    background-color: rgba(variables.$color-disabled, 0.15);

    @media (max-width: variables.$device-md) {
      background-color: rgba(variables.$color-disabled, 0.25);
    }
  }
}

.logo {
  opacity: 0.675;

  a {
    @include layout.row(0.75rem);
  }

  @media (max-width: variables.$device-md) {
    margin-left: 0.5rem;
  }
}

.impact-number {
  @include layout.row(0.325rem);
  @include variables.label;
  margin-left: 0.25rem;
  border-left: 1px solid rgba(variables.$color-disabled, 0.5);
  padding-left: 0.75rem;
}

.desktop-only {
  @media (max-width: variables.$device-md) {
    display: none;
  }
}
