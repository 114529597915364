@use 'libs/styles/variables';
@use 'libs/styles/layout';

.footer-wrapper {
  @include layout.max-width;
  padding-top: 2rem;
  padding-bottom: 48px;
  color: variables.$color-slate;

  @media (max-width: variables.$device-md) {
    padding-inline: 1.5rem;
  }

  hr {
    width: 100%;
    height: 1px;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    border: none;
    background-color: variables.$color-lighter;
  }
}

.links-container {
  display: flex;
  gap: 3vw;
  justify-content: space-evenly;

  @media (max-width: variables.$device-sm) {
    flex-direction: column;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h6 {
      @include variables.paragraph;
      margin-top: 12px;
      margin-bottom: 4px;
      color: variables.$color-slate;
    }

    a {
      @include variables.label;
      color: variables.$color-disabled;
    }
  }
}

.links-logo {
  flex: 1;
  gap: 16px;

  span {
    @include variables.paragraph($big: true);
  }
}

.extra-container {
  display: flex;
  align-items: center;

  @media (max-width: variables.$device-md) {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
  }
}

.ndao-ein {
  @include variables.label;
  max-width: 260px;
  margin-top: 0;
  margin-bottom: 12px;
}

.ndao-copyright {
  @include variables.label;
  opacity: 0.5;
}

.extra-app-button {
  margin-left: auto;

  @media (max-width: variables.$device-md) {
    margin: unset;
  }
}
