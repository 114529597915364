@use 'libs/styles/variables';
@use 'libs/styles/layout';

.not-found {
  @include variables.title(1, false);
  margin: 0;
  margin-top: 16px;
  margin-bottom: 32px;
  color: variables.$color-dark-light;

  &__container {
    width: 100%;
    margin: 0;
    padding: 10vh;
    text-align: center;

    img {
      display: inline;
    }
  }
}
