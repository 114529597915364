@use 'libs/styles/variables';
@use 'libs/styles/layout';

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: variables.$NAVBAR_HEIGHT;

  &::before {
    @include layout.absolute-box;
    z-index: -2;
    height: 125%;
    background: linear-gradient(
      180deg,
      #fff 0%,
      rgba(255, 255, 255, 94%) 48.7%,
      rgba(255, 255, 255, 59%) 81.62%,
      rgba(255, 255, 255, 0%) 100%
    );
  }

  &::after {
    @include layout.absolute-box;
    z-index: -1;
    height: 125%;
    backdrop-filter: blur(10px);
    transition: variables.$transition-s;
    mask: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 94%) 48.7%, rgba(0, 0, 0, 59%) 81.62%, rgba(0, 0, 0, 0%) 100%);

    @media (max-width: variables.$device-md) {
      display: none;
    }
  }

  @media (max-width: variables.$device-md) {
    height: fit-content;
  }

  &__inner {
    @include layout.max-width;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 12px;
    padding-left: 1.5rem;

    @media (max-width: variables.$device-md) {
      padding: 12px 20px;

      & > *:last-child {
        button {
          margin-right: -10px;
          padding-right: 24px;
        }

        svg {
          display: none;
        }
      }
    }

    & > div,
    & > nav {
      flex-basis: 33%;

      @media (max-width: variables.$device-md) {
        &:first-child {
          height: 20px;
        }
      }
    }

    .app-button {
      margin-left: 0.5rem;
      font-size: 0.925rem;
    }

    *:not(.logo) a:not(.app-button) {
      @include variables.paragraph;
      position: relative;
      z-index: 1;
      padding: 7px 20px;
      font-weight: 500;
      line-height: revert;
      letter-spacing: -0.01em;
      white-space: nowrap;
      text-decoration: none;
      color: variables.$color-gray;
      transition: variables.$transition-s;

      &:hover,
      &.active {
        color: variables.$color-purple;
      }

      &.active {
        &::after {
          @include layout.absolute-box;
          top: unset;
          bottom: 0;
          left: 1rem;
          width: calc(100% - 2rem);
          height: 1px;
          background-color: rgba(variables.$color-purple, 0.5);
        }
      }
    }
  }
}

.navigation {
  @include layout.row(0);
  justify-content: flex-start;

  @media (max-width: variables.$device-md) {
    @include layout.column;
  }
}

.learn-container {
  position: relative;
  display: flex;

  svg {
    display: inline;
    margin-left: 1px;
    transition: variables.$transition-m;

    path {
      stroke: variables.$color-disabled;
    }
  }

  & > div {
    position: absolute;
    top: 32px;
    z-index: 1;
    display: none;
    flex-direction: column;
    margin: 6px -2px;
    border: 1px solid variables.$color-lighter;
    border-radius: variables.$border-radius-s;
    padding: 4px 12px;
    padding-bottom: 6px;
    white-space: nowrap;
    background-color: rgba(variables.$color-white, 95%);
    box-shadow: variables.$box-shadow-medium;
    backdrop-filter: saturate(1.8) blur(20px);

    & > a {
      padding: 12px 8px;
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid variables.$color-light-border;

      &:hover {
        border-color: rgba(variables.$color-purple, 0.5);
      }
    }
  }

  @media (hover: hover) {
    &:hover > div,
    & > div:hover {
      display: flex;
    }

    &:hover {
      & > a {
        color: variables.$color-purple;
      }

      svg {
        transform: scaleY(-1);

        path {
          stroke: variables.$color-purple;
        }
      }
    }
  }
}

.logo {
  display: flex;
  flex: 1;
  text-align: center;

  @media (min-width: variables.$device-md) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: variables.$device-md) {
    height: 30px;
  }

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
}

.extra {
  display: flex;
  gap: 0;
  justify-content: flex-end;
  align-items: center;

  .hamburger-icon {
    @include layout.row;
    flex: 0 0 20px;
    margin-left: 0.75rem;

    & > svg {
      display: inline-block;
    }

    @media (min-width: variables.$device-md) {
      display: none;
    }
  }

  a {
    height: fit-content;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 1.05rem;

    @media (max-width: variables.$device-md) {
      font-size: 0.95rem;
    }
  }
}

.active-indicator {
  @include layout.absolute-box;
  width: 100%;
  height: 100%;
  border-radius: 4rem;
  background: rgba(variables.$color-purple, 0.125);
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: variables.$color-near-white;

  & > svg {
    align-self: flex-end;
  }

  & > nav {
    align-items: flex-start;

    a {
      @include variables.title(4);
      margin-bottom: 8px;
      text-align: left;
      color: variables.$color-disabled;

      &.active {
        color: variables.$color-purple;
      }
    }

    .sub-link {
      @include variables.header(3);
      margin-top: -4px;
      margin-left: 12px;
    }

    & > :last-child {
      width: fit-content;
      margin-top: 24px;
    }
  }

  & > div {
    width: 100%;
    margin-top: auto;

    & > hr {
      height: 1px;
      margin-right: -10px;
      margin-bottom: 55px;
      margin-left: -10px;
      border: none;
      background-color: rgba(variables.$color-slate, 0.42);
    }

    & > svg > path {
      fill: variables.$color-slate;
    }
  }
}

@media (min-width: variables.$device-md) {
  .show-mobile {
    display: none;
  }
}

@media (max-width: variables.$device-md) {
  .show-desktop {
    display: none;
  }
}
